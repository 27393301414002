import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Bad WiFi",
  "path": "/Frequently_Asked_Question/Bad_WiFi/",
  "dateChanged": "2018-03-15",
  "author": "Mike Polinowski",
  "excerpt": "Although the WLAN signal is perfect, the connection often breaks off.",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "HD Cameras"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='FAQs - Bad WiFi' dateChanged='2018-03-15' author='Mike Polinowski' tag='INSTAR IP Camera' description='Although the WLAN signal is perfect, the connection often breaks off.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/Bad_WiFi/' locationFR='/fr/Frequently_Asked_Question/Bad_WiFi/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "bad-wifi",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#bad-wifi",
        "aria-label": "bad wifi permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bad WiFi`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: Although the WLAN signal is perfect, the connection often breaks off.`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Although the wireless signal strength is perfect, the connection is constantly breaking. I've already tried everything and even set a repeater in between and measured the WiFi strength with the phone.`}</p>
    <p>{`You can search for networks in the WebUI of the camera under Network / WLAN (`}<Link to="/Web_User_Interface/1080p_Series/Network/WiFi/" mdxType="Link">{`1080p`}</Link>{` / `}<Link to="/Web_User_Interface/720p_Series/Network/WiFi/" mdxType="Link">{`720p`}</Link>{`). You will also see the connection strength. What is displayed there for your network? If there is an unrealistically low value, this would be an indication of a problem with the WLAN module, the antenna cable or the antenna (the clearest test is to place the camera next to the router, then you should get 100% connection strength).`}</p>
    <p>{`If you have previously used other repeaters, it would be important that they do not have the same SSID as the main router. Since the camera goes only after the SSID and connects to it, it may happen that the camera otherwise connects to the wrong access point, which may then be far away and thus has only a bad connection.`}</p>
    <p>{`In routers such as the AVM Fritzbox you have the opportunity once the wireless channels to scan through to see if the Fritzbox and the repeater eventual use a wireless channel, which is heavily used in the area. If there are a lot of devices on the same channel, then I recommend you change the channel manually. Disturbing WLAN signals can disturb not only the connection between repeater and camera, but also the connection between repeater and router.`}</p>
    <p>{`In addition, one should check which encryption the router or the repeater use. The mixed mode WPA + WPA2 should not be used. It's best to use the WPA2 (AES / CCMP). Of course, you should first make sure that the cameras have selected the WPA2 and AES modes.`}</p>
    <p>{`The mixed mode is usually only used if you have very old WLAN devices in use that do not support WPA2. Since WPA no longer offers security, you should not use it.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      